<template>
  <div>
    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteEventDefinition($event, null , true)"
      @emitExportToExcel="exportToExcel"
    />
    <tm-popup
      :submit="onSubmit"
      :title="dialogTitle"
      ref="popup"
    >
      <div class="vx-row justify-center">
        <div class="vx-col  w-full text-center">
          <h4 class="my-5">
            الصورة
          </h4>
          <file-pond-component
            :file="eventDefinition.logo"
            @fileDeleted="fileDeleted"
            @fileUploaded=" fileUploaded"
            :rules="{required: false}"
            project="website"
          />
        </div>
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="الاسم"
            placeholder="الاسم"
            type="text"
            v-model="eventDefinition.name"
            validation-rules="required"
          />
        </div>
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="الموقع"
            placeholder="الموقع"
            type="text"
            v-model="eventDefinition.location"
            validation-rules="required"
          />
        </div>
        <div class="vx-col w-full mt-2">
          <tm-input
            class="w-full"
            name="سعر الكرسي"
            placeholder="سعر الكرسي"
            type="number"
            v-model="eventDefinition.chair_price"
            validation-rules="required"
          />
        </div>
        <div class="vx-col w-full mt-2">
          <color-picker
            :color="defaultColor"
            ref="colorPicker"
            v-model="eventDefinition.color"
          />
        </div>
      </div>
    </tm-popup>
  </div>
</template>

<script>
  import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';
  import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
  import AgButton from "../../shared/shared-components/ag-grid/AgButton";
  import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
  import utilities from "@/app/shared/utilities";
  import TmPopup from "@/app/shared/shared-components/TmPopup";
  import TmInput from "@/app/shared/shared-components/TmInput";
  import colorPicker from "@/app/shared/shared-components/colorPicker";
  import AgTableImageCell from "@/app/shared/shared-components/ag-grid/AgTableImageCell";
  import FilePondComponent from "@/app/shared/shared-components/FilePondComponent";

  const eventsDefinitionRepo = RepositoryFactory.get("eventsDefinitionRepository");
  export default {
    name: "EventsDefinition",
    components: {
      AgTable,
      TmPopup,
      TmInput,
      colorPicker,
      FilePondComponent
    },
    data() {
      return {
        gridApi: null,
        eventDefinition: {
          id: null,
          name: '',
          chair_price: '',
          logo:'',
          location:'',
          color:''
        },
        defaultColor: '#4B364E',
        gridOptions: null,
        dialogTitle: '',
        permissions: {
          add: 'store_event_definition',
          delete: 'destroy_event_definition',
          edit: 'update_event_definition'
        }
      };
    },
    methods: {
      deleteEventDefinition(ids, index, isMultiple = false) {
        if (isMultiple)
          ids = ids.join(',');
        eventsDefinitionRepo.deleteEventDefinition(ids).then((response) => {
          if (response.code === 200) {
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      getAll(query) {
        return eventsDefinitionRepo.fetchAllEventsDefinition(query).then((response => response));
      },
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            suppressSizeToFit: true,
            checkboxSelection: true,
            filter: 'agNumberColumnFilter',
            width: 100,
          },
          {
            headerName: 'الصورة',
            field: 'logo',
            cellRendererFramework: AgTableImageCell,
            cellRendererParams: {
              width:'90%'
            },
            width: 100,
          },
          {
            headerName: 'الاسم',
            field: 'name',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الموقع',
            field: 'location',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'اللون',
            field: 'color',
            filter: 'agTextColumnFilter',
            cellStyle: self.changeCellColor,
            width: 100,
          },
          {
            headerName: 'سعر الكرسي',
            field: 'chair_price',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'أوقات الحفلة',
            cellRendererFramework: AgButton,
            valueGetter:
              function getObject(params) {
                return params;
              },
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'أوقات الحفلة',
              type: 'gradient',
              permission: 'get_events',
              click(valueGetter) {
                self.eventClick(valueGetter);
              }
            },
            sortable: false,
          },
          {
            headerName: 'الحجوزات',
            cellRendererFramework: AgButton,
            valueGetter:
              function getObject(params) {
                return params.data.id;
              },
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'الحجوزات',
              type: 'gradient',
              permission: 'get_event_users',
              click(valueGetter) {
                self.rowClicked(valueGetter);
              }
            },
            sortable: false,
            width: 100,
          },
          {
            headerName: 'الإحصائيات',
            cellRendererFramework: AgButton,
            valueGetter:
              function getObject(params) {
                return params.data.id;
              },
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'الإحصائيات',
              type: 'gradient',
              permission: 'get_events_definition',
              click(valueGetter) {
                self.statisticsClicked(valueGetter);
              }
            },
            sortable: false,
            width: 100,
          },
          {
            headerName: 'الإجراءات',
            valueGetter:
              function getObject(params) {
                return params.data.id;
              },
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              deleteRecord: function (valueGetter, rowIndex) {
                self.deleteEventDefinition(valueGetter, rowIndex);
              },
              editRecord: function (eventDefinition) {
                self.editRecord(eventDefinition);
              },
              actions: ['edit', 'delete'],
              permissions: self.permissions
            },
            sortable: false,
            width: 100,
          },
        ];
      },
      rowClicked(id) {
        this.$router.push(`/eventUsers/${id}`);
      },
      statisticsClicked(id) {
        this.$router.push(`/statistics/${id}`);
      },
      eventClick(eventDefinition) {
        this.$router.push(`/events/${eventDefinition.data.id}/name/${eventDefinition.data.name}`);
      },
      createRecord() {
        utilities.initObjectMembers(this.eventDefinition);
        this.defaultColor = '#4B364E';
        this.dialogTitle = 'إضافة حفلة';
        this.$refs.colorPicker.setColor(this.defaultColor);
        this.openPopup();
      },
      openPopup() {
        this.$refs.popup.open();
      },
      editRecord(eventDefinition) {
        this.dialogTitle = 'تعديل حفلة';
        Object.assign(this.eventDefinition, eventDefinition);
        this.defaultColor = eventDefinition.color;
        this.$refs.colorPicker.setColor(this.defaultColor);
        this.openPopup();
      },
      checkColor(color){
        if(color.startsWith("rgba"))
          this.eventDefinition.color = utilities.rgba2hex(color);
      },
      changeCellColor(params) {
        return {'color': params.node.data.color };
      },
      onSubmit() {
        this.checkColor(this.eventDefinition.color);
        if (this.eventDefinition.id !== null) {
          this.updateEventDefinition(this.eventDefinition);
        } else {
          this.storeEventDefinition(this.eventDefinition);
        }
      },

      storeEventDefinition(eventDefinition) {
        eventsDefinitionRepo.storeEventDefinition(this.$objectToFD(eventDefinition)).then((response) => {
          this.$refs.popup.endLoading();
          if (response.code === 200) {
            this.$refs.popup.close();
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      updateEventDefinition(eventDefinition) {
        eventsDefinitionRepo.updateEventDefinition(this.$objectToFD(eventDefinition), eventDefinition.id).then((response) => {
          this.$refs.popup.endLoading();
          if (response.code === 200) {
            this.$refs.popup.close();
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      exportToExcel() {
        eventsDefinitionRepo.exportEventDefinitionToExcel();
      },
      fileUploaded(file) {
        this.eventDefinition.logo = file;
      },
      fileDeleted() {
        this.eventDefinition.logo = null;
      },
    },
    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  };
</script>
